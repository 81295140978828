import client from './http';

class TrainingsService {
  constructor(axios) {
    this.axios = axios;
  }

  // Library
  getLibraryTrainings(search) {
    return this.axios.get('/library/trainings', { params: { search } });
  }

  getLibraryScormCourses() {
    return this.axios.get('/library/trainings/scorm-courses');
  }

  addLibraryTraining(data) {
    return this.axios.post('/library/trainings', data);
  }

  deleteLibraryTraining(trainingId) {
    return this.axios.delete(`/library/trainings/${trainingId}`);
  }

  updateLibraryTraining(trainingId, data) {
    return this.axios.put(`/library/trainings/${trainingId}`, data);
  }

  // Program
  getProgramTrainings(programId, search) {
    return this.axios.get(`/programs/${programId}/trainings`, { params: { search } });
  }

  getProgramTraining(programId, trainingId) {
    return this.axios.get(`/programs/${programId}/trainings/${trainingId}`);
  }

  addTrainingToProgram(programId, data) {
    return this.axios.patch(`/programs/${programId}/trainings/`, data);
  }

  updateProgramTraining(programId, trainingId, data) {
    return this.axios.patch(`/programs/${programId}/trainings/${trainingId}`, data);
  }

  deleteProgramTraining(programId, trainingId) {
    return this.axios.delete(`/programs/${programId}/trainings/${trainingId}`);
  }

  launchProgramTraining(programId, trainingId) {
    return this.axios.post(`/programs/${programId}/trainings/${trainingId}/launch`);
  }
  
  assignTrainings(programId, userId, data) {
    return this.axios.post(`/programs/${programId}/users/${userId}/assign-trainings`, data);
  }

  getTrainingUserList(programId, trainingId, search) {
    return this.axios.get(`/programs/${programId}/trainings/${trainingId}/users`, { params: { search } });
  }

  getTrainingLaunchHistories(programId, userId) {
    return this.axios.get(`/programs/${programId}/users/${userId}/trainings/launch-histories`);
  }

  markProgramTrainingsComplete(programId, data) {
    return this.axios.post(`/programs/${programId}/trainings/mark-completed`, data);
  }

  // User Trainings
  getUserTrainings(programId, userId, search) {
    return this.axios.get(`/programs/${programId}/users/${userId}/trainings`, { params: { search } });
  }

  createTrainingThumbnail(data) {
    return this.axios.post(`/library/trainings/image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  updateTrainingThumbnail(id, data) {
    return this.axios.post(`/library/trainings/${id}/image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        _method: 'PATCH'
      }
    });
  }
  deleteTrainingThumbnail(id) {
    return this.axios.delete(`/library/trainings/${id}/image`);
  }
  updateSeatAllocated(programId, trainingId, data) {
    return this.axios.patch(`/programs/${programId}/trainings/${trainingId}/seats`, data);
  }
}

const trainingsService = new TrainingsService(client);
export default trainingsService;

export default [
  {
    path: '/terms',
    name: 'app-terms',
    component: () => import('@/views/apps/TermsAndPrivacy.vue'),
    meta: {
      layout: 'full-then-vertical',
      pageTitle: 'Art of Mentoring Terms of Acceptable Use',
      resource: 'Auth',
      publicRoute: true
    },
  },

  {
    path: '/privacy',
    name: 'app-privacy',
    component: () => import('@/views/apps/TermsAndPrivacy.vue'),
    meta: {
      layout: 'full-then-vertical',
      pageTitle: 'Art of Mentoring Privacy Policy',
      resource: 'Auth',
      publicRoute: true
    },
  },

  {
    path: '/champion/program/:id/dashboard',
    name: 'champion-home',
    component: () => import('@/views/apps/champion/dashboards/Dashboards.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/general',
    name: 'champion-program-general',
    component: () => import('@/views/apps/admin/programs/EditProgram.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general'
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/landingpage',
    name: 'champion-program-landing-page',
    component: () => import('@/views/apps/champion/landing-pages/LandingPage.vue'),
    meta: {
      pageTitle: 'Landing Page',
      pageTooltip: "Program landing page for new participants",
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Landing Page',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/applications',
    name: 'champion-program-application',
    component: () => import('@/views/apps/champion/applications/ApplicationFormPreview.vue'),
    meta: {
      pageTitle: 'Applications',
      pageTooltip: 'Program application form for mentors and mentees',
      navActiveLink: 'champion-program-applications-list',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Application',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/applications/:application/edit',
    name: 'champion-program-application-edit',
    component: () => import('@/views/apps/champion/applications/ApplicationFormEdit.vue'),
    meta: {
      pageTitle: 'Applications',
      navActiveLink: 'champion-program-applications-list',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Application',
          name: 'champion-program-application'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/applications/:application/result',
    name: 'champion-program-application-result',
    component: () => import('@/views/apps/champion/applications/ApplicationsResult.vue'),
    meta: {
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Application',
          name: 'champion-program-application'
        },
        {
          text: 'Result',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/library',
    name: 'champion-program-library',
    component: () => import('@/views/apps/champion/program/Library.vue'),
    meta: {
      pageTitle: 'Library',
      breadcrumb: [
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Library',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/surveys',
    name: 'champion-program-surveys-list',
    component: () => import('@/views/apps/champion/program/Surveys.vue'),
    meta: {
      pageTitle: 'Surveys',
      pageTooltip: 'Surveys that are sent to participants',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Surveys',
          active: true,
        }
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/surveys/:survey',
    name: 'champion-program-survey',
    component: () => import('@/views/apps/champion/surveys/SurveyFormPreview.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Surveys',
          name: 'champion-program-surveys-list',
        },
        {
          text: 'Survey',
          active: true,
        }
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/surveys/:survey/metric',
    name: 'champion-program-survey-metric',
    component: () => import('@/views/components/forms/surveys/SurveysMetric.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Surveys',
          name: 'champion-program-surveys-list',
        },
        {
          text: 'Survey',
          name: 'champion-program-survey',
        },
        {
          text: 'Metric',
          active: true,
        }
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/surveys/:survey/result',
    name: 'champion-program-survey-result',
    component: () => import('@/views/apps/champion/surveys/SurveyResult.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Surveys',
          name: 'champion-program-surveys-list',
        },
        {
          text: 'Survey',
          name: 'champion-program-survey',
        },
        {
          text: 'Result',
          active: true,
        }
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/surveys/:survey/edit',
    name: 'champion-program-survey-edit',
    component: () => import('@/views/apps/champion/surveys/SurveyFormEdit.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Surveys',
          name: 'champion-program-surveys-list',
        },
        {
          text: 'Survey',
          name: 'champion-program-survey',
        },
        {
          text: 'Edit',
          active: true,
        }
      ],
      isProgramRoute: true,
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/training',
    name: 'champion-program-training-list',
    component: () => import('@/views/apps/champion/training/Training.vue'),
    meta: {
      pageTitle: 'Training',
      pageTooltip: 'Training for participants',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Training',
          active: true,
        }
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/training/:trainingId/log',
    name: 'champion-program-training-log',
    component: () => import('@/views/apps/champion/training/ProgramTrainingLog.vue'),
    meta: {
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Training',
          name: 'champion-program-training-list',
        },
        {
          text: 'Log',
          active: true,
        }
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/training/:trainingId/participants',
    name: 'champion-program-training-participants',
    component: () => import('@/views/apps/champion/training/ProgramTrainingParticipants.vue'),
    meta: {
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Training',
          name: 'champion-program-training-list',
        },
        {
          text: 'Trainees',
          active: true,
        }
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/communications/:topicId/sent-messages',
    name: 'champion-program-communication-sent-messages',
    component: () => import('@/views/apps/champion/communication/SentMessageList.vue'),
    meta: {
      pageTitle: 'Communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications',
        },
        {
          text: 'Sent Messages',
          active: true,
        }
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/schedules/:topicId/sent-messages',
    name: 'champion-program-schedules-sent-messages',
    component: () => import('@/views/apps/champion/communication/SentMessageList.vue'),
    meta: {
      pageTitle: 'Scheduled',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Scheduled',
          name: 'champion-program-schedules',
        },
        {
          text: 'Sent Messages',
          active: true,
        }
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/key-dates',
    name: 'champion-program-key-dates-list',
    component: () => import('@/views/apps/champion/key-dates/KeyDates.vue'),
    meta: {
      pageTitle: 'Key Dates',
      pageTooltip: "Add and edit program key dates",
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Key Dates',
          active: true,
        }
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/communications',
    name: 'champion-program-communications',
    component: () => import('@/views/apps/champion/communication/Communication.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Messages',
      pageTooltip: 'View incoming comms',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Messages',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/communications/messages/:messageId',
    name: 'champion-program-communications',
    component: () => import('@/views/apps/champion/communication/Communication.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Messages',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Messages',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/schedules',
    name: 'champion-program-schedules',
    component: () => import('@/views/apps/champion/communication/scheduled/Schedules.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Scheduled',
      pageTooltip: 'Messages scheduled for participants',
      navActiveLink: 'champion-program-communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Scheduled',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/invites',
    name: 'champion-program-invites',
    component: () => import('@/views/apps/champion/communication/Invites.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Invites',
      pageTooltip: 'Invite participants to the program',
      navActiveLink: 'champion-program-communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Invites',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/invite-templates',
    name: 'champion-program-invite-templates',
    component: () => import('@/views/apps/champion/communication/invites/InviteTemplates.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Templates',
      navActiveLink: 'champion-program-communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Templates',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/sent',
    name: 'champion-program-sent',
    component: () => import('@/views/apps/champion/communication/Sent.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Sent',
      pageTooltip: 'Comms sent to participants',
      navActiveLink: 'champion-program-communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Sent',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/undelivered',
    name: 'champion-program-undelivered',
    component: () => import('@/views/apps/champion/communication/Undelivered.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      pageTitle: 'Undelivered',
      pageTooltip: 'Messages that were not delivered',
      navActiveLink: 'champion-program-communications',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Communications',
          name: 'champion-program-communications'
        },
        {
          text: 'Undelivered',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  {
    path: '/champion/program/:id/participants/groups',
    name: 'champion-participants-groups',
    component: () => import('@/views/apps/champion/groups/Groups.vue'),
    meta: {
      pageTitle: 'Groups',
      pageTooltip: 'Participants grouped by filters or added to a group',
      breadcrumb: [
        {
          text: 'Groups',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/participants/groups/:groupId',
    name: 'champion-participants-filter-groups',
    component: () => import('@/views/apps/champion/groups/FilterGroup.vue'),
    meta: {
      pageTitle: 'Group Participants',
      navActiveLink: 'champion-participants-groups',
      breadcrumb: [
        {
          text: 'Groups',
          name: 'champion-participants-groups'
        },
        {
          text: 'Group Participants',
          active: true
        }
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/participants/filtered-group',
    name: 'champion-advanced-search',
    component: () => import('@/views/apps/champion/groups/CreateFilterGroup.vue'),
    meta: {
      pageTitle: 'Groups',
      navActiveLink: 'champion-participants-groups',
      breadcrumb: [
        {
          text: 'Groups',
          name: 'champion-participants-groups'
        },
        {
          text: 'Filtered Group',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/participants/filtered-group/:groupId',
    name: 'champion-edit-filter-group',
    component: () => import('@/views/apps/champion/groups/EditFilterGroup.vue'),
    meta: {
      pageTitle: 'Groups',
      breadcrumb: [
        {
          text: 'Groups',
          name: 'champion-participants-groups'
        },
        {
          text: 'Filtered Group',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/participants',
    name: 'champion-participants',
    component: () => import('@/views/apps/participants/Participants.vue'),
    meta: {
      pageTitle: 'Participants',
      pageTooltip: 'List of program participants',
      breadcrumb: [
        {
          text: 'Participants',
          active: true,
        }
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/matches/livematches',
    name: 'champion-matches-live-matches',
    component: () => import('@/views/apps/champion/matches/LiveMatches.vue'),
    meta: {
      pageTitle: 'Live Matches',
      pageTooltip: 'Matches that have begun the program',
      breadcrumb: [
        {
          text: 'Matches',
          name: 'champion-matches-live-matches',
        },
        {
          text: 'Live Matches',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/matches/lockedmatches',
    name: 'champion-matches-locked-matches',
    component: () => import('@/views/apps/champion/matches/LockedMatches.vue'),
    meta: {
      pageTitle: 'Locked Matches',
      pageTooltip: 'Locked matches prior to publishing',
      breadcrumb: [
        {
          text: 'Matches',
          name: 'champion-matches-live-matches'
        },
        {
          text: 'Locked Matches',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/admin/participants',
    name: 'admin-participants',
    component: () => import('@/views/apps/participants/Participants.vue'),
    meta: {
      pageTitle: 'Participants',
      breadcrumb: [
        {
          text: 'Participants',
        },
        {
          text: 'List',
          active: true
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/matches/matching-pools',
    name: 'champion-matches-matching-pools',
    component: () => import('@/views/apps/champion/matches/MatchingPools.vue'),
    meta: {
      pageTitle: 'Matching Pools',
      pageTooltip: 'Matching pools of participants in preparation for program launch',
      breadcrumb: [
        {
          text: 'Matches',
          name: 'champion-matches-live-matches'
        },
        {
          text: 'Matching Pools',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/matches/pool-detail/:poolId',
    name: 'champion-matching-pool-details',
    component: () => import('@/views/apps/champion/matches/PoolDetails.vue'),
    meta: {
      pageTitle: 'Matching Pools',
      navActiveLink: 'champion-matches-matching-pools',
      breadcrumb: [
        {
          text: 'Matches',
          name: 'champion-matches-live-matches'
        },
        {
          text: 'Pool Details',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/reports',
    name: 'champion-reports',
    component: () => import('@/views/apps/champion/reports/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      pageTooltip: 'View updated reports of participant activity',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/reports/login',
    name: 'champion-monthly-reports',
    component: () => import('@/views/apps/champion/reports/MonthlyLogin.vue'),
    meta: {
      pageTitle: 'Reports',
      navActiveLink: 'champion-reports',
      breadcrumb: [
        {
          text: 'Reports',
          name: 'champion-reports'
        },
        {
          text: 'Logins',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/reports/login/:loginId',
    name: 'champion-program-login-report',
    component: () => import('@/views/apps/champion/reports/ParticipantActivityReport.vue'),
    props: true,
    meta: {
      pageTitle: 'Reports',
      navActiveLink: 'champion-reports',
      breadcrumb: [
        {
          text: 'Reports',
          name: 'champion-reports'
        },
        {
          text: 'Activity',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/reports/resources',
    name: 'champion-monthly-resources',
    component: () => import('@/views/apps/champion/reports/MonthlyResources.vue'),
    meta: {
      pageTitle: 'Reports',
      navActiveLink: 'champion-reports',
      breadcrumb: [
        {
          text: 'Reports',
          name: 'champion-reports'
        },
        {
          text: 'Resources',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/reports/messages',
    name: 'champion-monthly-comms',
    component: () => import('@/views/apps/champion/reports/MonthlyComms.vue'),
    meta: {
      pageTitle: 'Reports',
      navActiveLink: 'champion-reports',
      breadcrumb: [
        {
          text: 'Reports',
          name: 'champion-reports'
        },
        {
          text: 'Communications',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/championsupport/library',
    name: 'champion-support-library',
    component: () => import('@/views/apps/champion/champion-support/Library.vue'),
    meta: {
      pageTitle: 'Library',
      breadcrumb: [
        {
          text: 'Champion Support',
          name: 'champion-support-library'
        },
        {
          text: 'Library',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/championsupport/tutorials',
    name: 'champion-support-tutorials',
    component: () => import('@/views/apps/champion/champion-support/Tutorials.vue'),
    meta: {
      pageTitle: 'Tutorials',
      breadcrumb: [
        {
          text: 'Champion Support',
        },
        {
          text: 'Tutorials',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/championsupport/support',
    name: 'champion-support-support',
    component: () => import('@/views/apps/champion/champion-support/Support.vue'),
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Champion Support',
        },
        {
          text: 'Support',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },

  {
    path: '/champion/program/:id/championsupport/contactus',
    name: 'champion-support-contact-us',
    component: () => import('@/views/apps/champion/champion-support/ContactUs.vue'),
    meta: {
      pageTitle: 'Contact Us',
      breadcrumb: [
        {
          text: 'Champion Support',
        },
        {
          text: 'Contact Us',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/participant/:participantId',
    name: 'champion-program-participant',
    component: () => import('@/views/apps/participants/Participant.vue'),
    meta: {
      pageTitle: 'Participant',
      navActiveLink: 'champion-participants',
      breadcrumb: [
        {
          text: 'Participant',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/match-details/:matchId',
    name: 'champion-match-view',
    component: () => import('@/views/apps/champion/matches/MatchDetails.vue'),
    meta: {
      pageTitle: 'Match Detail',
      navActiveLink: 'champion-matches-live-matches',
      breadcrumb: [
        {
          text: 'Matches',
          name: 'champion-matches-live-matches'
        },
        {
          text: 'Match Detail',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/resources',
    name: 'champion-program-resources',
    component: () => import('@/views/apps/champion/resources/Resources.vue'),
    meta: {
      pageTitle: 'Resources',
      pageTooltip: 'Program resources for participants',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Resources',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/resources/:resource/logs',
    name: 'champion-program-resource-logs',
    component: () => import('@/views/apps/champion/resources/ResourceLogs.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Resources',
          name: 'champion-program-resources',
        },
        {
          text: 'Resource Logs',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/resources/:resource',
    name: 'champion-program-resource-view',
    component: () => import('@/views/components/forms/resources/ResourceDetail.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Resources',
          name: 'champion-program-resources',
        },
        {
          text: 'Resource Detail',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/change-password',
    name: 'champion-change-password',
    component: () => import('@/views/authentication/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      breadcrumb: [
        {
          text: 'Program',
        },
        {
          text: 'Change Password',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/profile/:userId/edit',
    name: 'champion-profile-edit',
    component: () => import('@/views/apps/admin/champion/ProfileChampion.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        }
      ],
      resource: 'ChampionRoutes',
    },
  },
  {
    path: '/champion/program/:id/participant/:participantId/training/logs',
    name: 'champion-program-participant-training-logs',
    component: () => import('@/views/apps/champion/training/TrainingLaunchHistory.vue'),
    meta: {
      pageTitle: 'Participant Training Logs',
      breadcrumb: [
        {
          text: 'Training Logs',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/participant/:participantId/activity/report',
    name: 'champion-program-participant-activity-report',
    component: () => import('@/views/apps/champion/reports/ParticipantActivityReport.vue'),
    props: true,
    meta: {
      pageTitle: 'Participant Training Logs',
      breadcrumb: [
        {
          text: 'Training Logs',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/participant/:participantId/application/:applicationId/mentor/edit',
    name: 'champion-program-mentor-application-edit',
    component: () => import('@/views/apps/participants/ChampionEditMentorApplication.vue'),
    meta: {
      pageTitle: 'Mentor Application',
      breadcrumb: [
        {
          text: 'Mentor Application',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/participant/:participantId/application/:applicationId/mentee/edit',
    name: 'champion-program-mentee-application-edit',
    component: () => import('@/views/apps/participants/ChampionEditMenteeApplication.vue'),
    meta: {
      pageTitle: 'Mentee Application',
      breadcrumb: [
        {
          text: 'Mentee Application',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/notifications',
    name: 'champion-program-notifications',
    component: () => import('@/views/apps/champion/notifications/Notifications.vue'),
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/group-notifications/:groupId',
    name: 'champion-program-group-notifications',
    component: () => import('@/views/apps/champion/notifications/GroupNotifications.vue'),
    props: true,
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
        }
      ],
      resource: 'ChampionRoutes',
    }
  },
  {
    path: '/champion/program/:id/supports/resources',
    name: 'champion-program-supports-resources',
    component: () => import('@/views/apps/champion/champion-support/resources/Resources.vue'),
    meta: {
      pageTitle: 'Library',
      pageTooltip: "Champion training library",
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
      resource: 'ChampionRoutes',
      params: {
        filter: {field: 'role', value: 2},
      },
    },
  },
  {
    path: '/champion/program/:id/application/:applicationId/print',
    name: 'print-application-matching-questions',
    component: () => import('@/views/components/forms/applications/PrintApplication.vue'),
    meta: {
      pageTitle: 'Program',
      layout: 'full',
      printPage: true,
      breadcrumb: [
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Print',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
  /* ================================================      END Champion    ============================================ */


  /*======================================================= A D M I N ================================================  */
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('@/views/apps/admin/home/Home.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  // *===============================================---*
  // *--------- CLIENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/admin/clients',
    name: 'admin-clients',
    component: () => import('@/views/apps/admin/clients/Clients.vue'),
    meta: {
      pageTitle: 'Organisations',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Organisations',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/client/:clientId',
    name: 'admin-client',
    component: () => import('@/views/apps/admin/clients/ClientDetail.vue'),
    props: true,
    meta: {
      pageTitle: 'General',
      navActiveLink: 'admin-clients',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Organisation',
          active: true,
        },
        {
          text: 'General',
          active: true,
        },
      ],
      resource: 'AdminRoutes'
    },
  },

  {
    path: '/admin/client/:clientId/participant/:userId',
    name: 'admin-client-participant',
    component: () => import('@/views/apps/admin/participants/ClientParticipant.vue'),
    props: true,
    meta: {
      pageTitle: 'General',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Organisation',
          active: true,
        },
        {
          text: 'Participant',
          active: true,
        },
      ],
      resource: 'AdminRoutes'
    },
  },

  {
    path: '/admin/client/:clientId/transfer',
    name: 'admin-client-transfer',
    component: () => import('@/views/apps/admin/clients/TransferProgram.vue'),
    props: true,
    meta: {
      pageTitle: 'Transfer to Program',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Organisation',
          active: true,
        },
        {
          text: 'General',
          active: true,
        },
        {
          text: 'Transfer',
          active: true,
        },
      ],
      resource: 'AdminRoutes'
    },
  },

  // *===============================================---*
  // *--------- PROGRAMS ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/admin/programs',
    name: 'admin-programs',
    component: () => import('@/views/apps/admin/programs/Programs.vue'),
    meta: {
      pageTitle: 'Programs',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/templates',
    name: 'admin-templates',
    component: () => import('@/views/apps/admin/programs/Templates.vue'),
    meta: {
      pageTitle: 'Templates',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Templates',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/editprograms',
    name: 'apps-editprograms',
    component: () => import('@/views/apps/admin/programs/EditProgram.vue'),
    meta: {
      pageTitle: 'Programs',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/apps/admin/users/AdminUser.vue'),
    meta: {
      pageTitle: 'Administrators',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Administrators',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/resources',
    name: 'admin-resources-list',
    component: () => import('@/views/apps/admin/resources/Resources.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Resources',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/resources/:resource',
    name: 'admin-resources-detail',
    component: () => import('@/views/components/forms/resources/ResourceDetail.vue'),
    meta: {
      pageTitle: 'Resources',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Resources',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/resource-categories',
    name: 'apps-resource-categories',
    component: () => import('@/views/apps/admin/resources/ResourceCategories.vue'),
    meta: {
      pageTitle: 'Resource categories',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Resources',
          active: true,
        },
        {
          text: 'Resource categories',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/champion/program/:id/resource-categories',
    name: 'champion-resource-categories',
    component: () => import('@/views/apps/champion/resources/ResourceCategories.vue'),
    meta: {
      pageTitle: 'Resource categories',
      breadcrumb: [
        {
          text: 'Program',
          name:'champion-program-general',
        },
        {
          text: 'Resources',
          name:'champion-program-resources',
        },
        {
          text: 'Resource categories',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },

  {
    path: '/admin/applications',
    name: 'admin-application-list',
    component: () => import('@/views/components/forms/applications/Applications.vue'),
    meta: {
      pageTitle: 'Application Lists',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Application Lists',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/application/:application',
    name: 'admin-application',
    component: () => import('@/views/apps/admin/applications/ApplicationFormPreview.vue'),
    meta: {
      pageTitle: 'Application',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Application',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/application/:application/edit',
    name: 'admin-application-edit',
    component: () => import('@/views/apps/admin/applications/ApplicationFormEdit.vue'),
    meta: {
      pageTitle: 'Application',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Application',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/applications/questions',
    name: 'admin-application-questions',
    component: () => import('@components/questions/Questions.vue'),
    meta: {
      pageTitle: 'Questions',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Questions',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/training',
    name: 'admin-training-list',
    component: () => import('@/views/apps/admin/training/Training.vue'),
    meta: {
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Training',
          active: true,
        },
        {
          text: 'Training List',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/surveys',
    name: 'admin-survey-list',
    component: () => import('@/views/components/forms/surveys/Surveys.vue'),
    meta: {
      pageTitle: 'Surveys ',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Survey Lists',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/surveys/:survey',
    name: 'admin-survey',
    component: () => import('@/views/apps/admin/surveys/SurveyFormPreview.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Surveys',
          active: true,
        },
        {
          text: 'Survey',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/surveys/:survey/edit',
    name: 'admin-survey-edit',
    component: () => import('@/views/apps/admin/surveys/SurveyFormEdit.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Surveys',
          active: true,
        },
        {
          text: 'Survey',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/surveys/questions',
    name: 'admin-survey-questions',
    component: () => import('@components/questions/Questions.vue'),
    meta: {
      pageTitle: 'Questions',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Surveys',
          active: true,
        },
        {
          text: 'Questions',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/communications',
    name: 'admin-communications-list',
    component: () => import('@/views/apps/admin/communications/Communications.vue'),
    meta: {
      pageTitle: 'Communications',
      contentClass: 'email-application',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Communications',
          active: true,
        },
        {
          text: 'Communications Lists',
          active: true,
        },
      ],
      fetchActionableEvent: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/knowledgebase',
    name: 'apps-knowledgebase',
    component: () => import('@/views/apps/admin/knowledgebase/Knowledgebase.vue'),
    meta: {
      pageTitle: 'Knowledgebase',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Knowledgebase',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/knowledgebasecard/:id',
    name: 'apps-knowledgebases-card',
    component: () => import('@/views/apps/admin/knowledgebase/KnowledgebaseCardView.vue'),
    meta: {
      pageTitle: 'Resource',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Knowledgebase Cards',
          active: true,
        }
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/addKnowledgebase',
    name: 'apps-add-knowledgebase',
    component: () => import('@/views/apps/admin/knowledgebase/AddKnowledgebase.vue'),
    meta: {
      pageTitle: 'Knowledgebase',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Add Knowledgebase',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },

  {
    path: '/admin/editKnowledgebase',
    name: 'apps-edit-knowledgebase',
    component: () => import('@/views/apps/admin/knowledgebase/EditKnowledgebase.vue'),
    meta: {
      pageTitle: 'Knowledgebase',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Library',
          active: true,
        },
        {
          text: 'Edit Knowledgebase',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/program/:id',
    name: 'admin-program',
    component: () => import('@/views/apps/admin/programs/EditProgram.vue'),
    meta: {
      pageTitle: 'Settings',
      contentClass: 'email-application',
      navActiveLink: 'admin-programs',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
      fetchActionableEvent: true
    },
  },
  {
    path: '/admin/template/:id',
    name: 'admin-template',
    component: () => import('@/views/apps/admin/programs/EditProgram.vue'),
    meta: {
      pageTitle: 'Settings',
      contentClass: 'email-application',
      navActiveLink: 'admin-templates',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Templates',
          active: true,
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
      fetchActionableEvent: true
    },
  },
  {
    path: '/admin/program/:id/applications/:application',
    name: 'admin-program-application',
    component: () => import('@/views/apps/admin/applications/ApplicationFormPreview.vue'),
    meta: {
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Application',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/program/:id/applications/:application/edit',
    name: 'admin-program-application-edit',
    component: () => import('@/views/apps/admin/applications/ApplicationFormEdit.vue'),
    meta: {
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Applications',
          active: true,
        },
        {
          text: 'Application',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/program/:id/surveys/:survey',
    name: 'admin-program-survey',
    component: () => import('@/views/apps/admin/surveys/SurveyFormPreview.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Surveys',
          active: true,
        },
        {
          text: 'Survey',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/program/:id/surveys/:survey/edit',
    name: 'admin-program-survey-edit',
    component: () => import('@/views/apps/admin/surveys/SurveyFormEdit.vue'),
    meta: {
      pageTitle: 'Surveys',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Program',
          active: true,
        },
        {
          text: 'Surveys',
          active: true,
        },
        {
          text: 'Survey',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      isProgramRoute: true,
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/languages',
    name: 'admin-languages-list',
    component: () => import('@/views/apps/admin/languages/Languages.vue'),
    meta: {
      pageTitle: 'Languages',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Configuration',
          active: true,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/platform-metrics',
    name: 'platform-metrics',
    component: () => import('@/views/apps/admin/platform-metrics/PlatformMetrics.vue'),
    meta: {
      pageTitle: 'Platform Metrics',
      breadcrumb: [
        {
          text: 'Administration',
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/tooltips',
    name: 'apps-tooltips',
    component: () => import('@/views/apps/admin/tooltips/Tooltips.vue'),
    meta: {
      pageTitle: 'Tooltips',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Configuration',
          active: true,
        },
        {
          text: 'Tooltips',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  }, 
  {
    path: '/admin/accesscontrol',
    name: 'apps-access-control',
    component: () => import('@/views/apps/admin/access-control/AccessControl.vue'),
    meta: {
      pageTitle: 'Access Control List',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Configuration',
          active: true,
        },
        {
          text: 'Access Control List',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  
  {
    path: '/admin/systeminformation',
    name: 'apps-system-information',
    component: () => import('@/views/apps/admin/system-information/SystemInformation.vue'),
    meta: {
      pageTitle: 'System Information',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Configuration',
          active: true,
        },
        {
          text: 'System Information',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/change-password',
    name: 'admin-change-password',
    component: () => import('@/views/authentication/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      breadcrumb: [
        {
          text: 'Champion',
        },
        {
          text: 'Program',
        },
        {
          text: 'Change Password',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/admin/search',
    name: 'admin-search',
    component: () => import('@/views/components/common/Search.vue'),
    meta: {
      pageTitle: 'Search Results',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Search Results',
          active: true,
        },
      ],
      resource: 'AdminRoutes',
    },
  },
  {
    path: '/champion/program/:id/search',
    name: 'champion-search',
    component: () => import('@/views/components/common/Search.vue'),
    meta: {
      pageTitle: 'Search Results',
      breadcrumb: [
        {
          text: 'Champion',
        },
        {
          text: 'Search Results',
          active: true,
        },
      ],
      resource: 'ChampionRoutes'
    },
  },
];
